import React from "react";

import { useTranslation } from "react-i18next";

function Footer() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-black">
            <div className="bg-black max-w-[1280px] mx-[20px] xl:mx-auto pt-[160px]">
                <div className="h-px bg-white"></div>
                <div className="flex flex-col xl:flex-row justify-between gap-[20px] mt-[20px] xl:mt-[60px]">
                    <div className="flex flex-col xl:flex-row gap-[20px] xl:gap-[150px]">
                        <div className="flex flex-col gap-[20px]">
                            <a href=""><p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">Address</p></a>
                            <a href=""><p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">Phone</p></a>
                            <a href=""><p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">E-Mail</p></a>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <a href=""><p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">Policy Privacy</p></a>
                            <a href=""><p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">Terms and Conditions</p></a>
                            <a href=""><p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">Client Agreement</p></a>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[20px] justify-between">
                        <div className="flex gap-[30px]">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="39" viewBox="0 0 38 39" fill="none">
                                    <path d="M25.9268 5.25545C27.7625 5.26093 29.5213 5.99256 30.8193 7.29055C32.1173 8.58853 32.8489 10.3474 32.8544 12.183V26.0367C32.8489 27.8723 32.1173 29.6312 30.8193 30.9292C29.5213 32.2272 27.7625 32.9588 25.9268 32.9643H12.0732C10.2375 32.9588 8.47867 32.2272 7.18068 30.9292C5.8827 29.6312 5.15107 27.8723 5.14559 26.0367V12.183C5.15107 10.3474 5.8827 8.58853 7.18068 7.29055C8.47867 5.99256 10.2375 5.26093 12.0732 5.25545H25.9268ZM25.9268 2.48486H12.0732C6.73906 2.48486 2.375 6.84893 2.375 12.183V26.0367C2.375 31.3708 6.73906 35.7349 12.0732 35.7349H25.9268C31.2609 35.7349 35.625 31.3708 35.625 26.0367V12.183C35.625 6.84893 31.2609 2.48486 25.9268 2.48486Z" fill="#BCFF2F"/>
                                    <path d="M28.005 12.183C27.5939 12.183 27.1922 12.0612 26.8504 11.8328C26.5087 11.6045 26.2423 11.2799 26.085 10.9002C25.9277 10.5205 25.8866 10.1026 25.9668 9.6995C26.047 9.29638 26.2449 8.92609 26.5355 8.63546C26.8261 8.34483 27.1964 8.14691 27.5995 8.06673C28.0027 7.98654 28.4205 8.02769 28.8002 8.18498C29.18 8.34227 29.5045 8.60863 29.7329 8.95038C29.9612 9.29212 30.0831 9.69391 30.0831 10.1049C30.0837 10.378 30.0303 10.6485 29.9261 10.9009C29.8219 11.1533 29.6688 11.3826 29.4757 11.5757C29.2826 11.7688 29.0533 11.9218 28.8009 12.0261C28.5485 12.1303 28.278 12.1836 28.005 12.183ZM19 13.568C20.0961 13.568 21.1676 13.893 22.0789 14.5019C22.9903 15.1109 23.7006 15.9764 24.1201 16.9891C24.5395 18.0017 24.6493 19.116 24.4354 20.1911C24.2216 21.2661 23.6938 22.2536 22.9187 23.0286C22.1437 23.8037 21.1562 24.3315 20.0812 24.5453C19.0062 24.7591 17.8919 24.6494 16.8792 24.2299C15.8666 23.8105 15.001 23.1002 14.3921 22.1888C13.7831 21.2774 13.4581 20.206 13.4581 19.1099C13.4597 17.6406 14.044 16.2319 15.083 15.1929C16.122 14.1539 17.5307 13.5695 19 13.568ZM19 10.7974C17.3559 10.7974 15.7488 11.2849 14.3818 12.1983C13.0148 13.1117 11.9494 14.4099 11.3203 15.9288C10.6911 17.4477 10.5265 19.1191 10.8472 20.7316C11.168 22.344 11.9597 23.8252 13.1222 24.9877C14.2847 26.1502 15.7658 26.9419 17.3783 27.2627C18.9908 27.5834 20.6621 27.4188 22.1811 26.7896C23.7 26.1605 24.9982 25.095 25.9116 23.7281C26.825 22.3611 27.3125 20.7539 27.3125 19.1099C27.3125 16.9053 26.4367 14.791 24.8778 13.2321C23.3189 11.6732 21.2046 10.7974 19 10.7974Z" fill="#BCFF2F"/>
                                </svg>
                            </a>
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="39" viewBox="0 0 38 39" fill="none">
                                    <path d="M32.7899 5.89649C32.7899 5.89649 35.8655 4.69712 35.6082 7.60966C35.5235 8.80904 34.7548 13.0072 34.1563 17.5475L32.1059 30.9979C32.1059 30.9979 31.9349 32.9683 30.3967 33.3111C28.8593 33.6531 26.5524 32.1117 26.1249 31.769C25.7829 31.5117 19.7171 27.6562 17.5812 25.7721C16.9827 25.2575 16.2987 24.2299 17.6667 23.0305L26.6371 14.4631C27.6623 13.4355 28.6875 11.0368 24.4157 13.9493L12.4536 22.0877C12.4536 22.0877 11.0864 22.945 8.52374 22.174L2.9694 20.46C2.9694 20.46 0.918988 19.1751 4.42211 17.8902C12.9666 13.8638 23.4759 9.75191 32.7883 5.89649H32.7899Z" fill="white"/>
                                </svg>
                            </a>
                        </div>
                        <div>
                            <p className="text-white text-[15px] xl:text-[17px] roboto font-[300]">ExchangeXpress. All rights reserved.2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );
}

export default Footer;