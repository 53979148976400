import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.svg";

function Header() {

    const { t } = useTranslation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'ru');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="bg-black relative z-[999]">
            <div className="max-w-[1280px] h-[60px] xl:h-[100px] justify-between mx-auto flex items-center px-[20px]">
                <div>
                    <img src={logo} alt="" />
                </div>
                <div className="flex sm:gap-[60px]">
                    <Dropdown
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <a href="#" className="hidden sm:block">
                        <div className="flex items-center justify-center xl:w-[139px] p-[20px] h-[30px] xl:h-[42px] rounded-[10px] bg-[#BCFF2F]">
                            <p className="roboto letterspacing1 font-[700] text-[14px] xl:text-[16px]">{t('header.1')}</p>
                        </div>
                    </a>
                </div>
            </div>
           
        </div>
    );
}

export default Header;