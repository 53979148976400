import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import debounce from 'lodash/debounce'; // Import debounce function

function ExchangeSystem() {
    
    const { t } = useTranslation();

    const [baseCurrencies, setBaseCurrencies] = useState([]);
    const [targetCurrencies, setTargetCurrencies] = useState([]);

    const [activeBaseCurrencyIndex, setActiveBaseCurrencyIndex] = useState(0);
    const [activeTargetCurrencyIndex, setActiveTargetCurrencyIndex] = useState(0); 

    const [exchangeRate, setExchangeRate] = useState(null);

    const [baseValue, setBaseValue] = useState('');
    const [targetValue, setTargetValue] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [wallet, setWallet] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

    const [formError, setFormError] = useState(''); // Add state for form errors
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.exchange-xpress.com/api/currencies/getCurrencies');
                if (response.status === 200) {
                    setBaseCurrencies(response.data.data.baseCurrencies);
                    setTargetCurrencies(response.data.data.targetCurrencies);
                } else {
                    setError('Failed to fetch data');
                }
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchExchangeRate = useCallback(debounce(async (baseCurrency, targetCurrency) => {
        if (baseCurrency && targetCurrency) {
            try {
                const response = await axios.get(`https://api.exchange-xpress.com/api/currencies/getRate?baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}`);
                if (response.status === 200) {
                    setExchangeRate(response.data.exchangeRate);
                } else {
                    setExchangeRate(null);
                    setError('Failed to fetch exchange rate');
                }
            } catch (err) {
                setExchangeRate(null);
                setError('Failed to fetch exchange rate');
            }
        }
    }, 200), [baseCurrencies, targetCurrencies]);

    useEffect(() => {
        if (activeBaseCurrencyIndex !== null && activeTargetCurrencyIndex !== null) {
            const baseCurrency = baseCurrencies[activeBaseCurrencyIndex]?.symbol;
            const targetCurrency = targetCurrencies[activeTargetCurrencyIndex]?.symbol;
            fetchExchangeRate(baseCurrency, targetCurrency);
        }
    }, [activeBaseCurrencyIndex, activeTargetCurrencyIndex, fetchExchangeRate]);

    const handleBaseValueChange = (e) => {
        const value = e.target.value;
        setBaseValue(value);
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue) && exchangeRate) {
            setTargetValue((numericValue * exchangeRate).toFixed(2)); // Convert based on exchange rate
        } else {
            setTargetValue(''); // Clear target value if input is invalid
        }
    };

    const handleTargetValueChange = (e) => {
        const value = e.target.value;
        setTargetValue(value);
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue) && exchangeRate) {
            setBaseValue((numericValue / exchangeRate).toFixed(2)); // Convert based on exchange rate
        } else {
            setBaseValue(''); // Clear base value if input is invalid
        }
    };

    const validateForm = () => {
        if (!baseValue || !targetValue || !name || !email || !wallet) {
            setFormError('All fields are required.');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setFormError('Email is invalid.');
            return false;
        }
        setFormError('');
        return true;
    };

    const sendTgMsg = async () => {

        if (!validateForm()) return;

        const baseCurrency = baseCurrencies[activeBaseCurrencyIndex].symbol;
        const targetCurrency = targetCurrencies[activeTargetCurrencyIndex].symbol;
        const sendAmount = baseValue;
        const receiveAmount = targetValue;

        const messageData = {
            name,
            email,
            wallet,
            baseCurrency,
            targetCurrency,
            sendAmount,
            receiveAmount,
            exchangeRate,
        };
        setName('');
        setEmail('');
        setWallet('');
        try {
            const result = await axios.post('https://api.exchange-xpress.com/api/exchange/sendRequest', messageData);
            if (result.status === 200) {
                setIsSuccessModalOpen(true);
                return true;
            } else {
                alert('Something went wrong. Try again');
                return false;
            }
        } catch (error) {
            if (error.response.data.status === 403) {
                alert(error.response.data.message);
                return;
            }
            alert(error.message);
        }
      }

    const closeSuccessModal = () => setIsSuccessModalOpen(false);

    if (loading) return <div className='text-white'>Loading...</div>;
    if (error) return <div className='text-white'>{error}</div>;  

    return (
        <div className="bg-black max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between gap-[10px]">
                <div className="xl:w-1/3">
                    <p className="roboto letterspacing2 font-[700] text-[#E7E7E7] text-[16px] xl:text-[22px] mx-[10px]">{t('home.7')}</p>
                    <div className='flex flex-col gap-[5px] mt-[40px] max-h-[700px] overflow-y-auto'>
                        {baseCurrencies.map((currency, index) => (
                            <div 
                                key={index}
                                className={`flex items-center justify-between hover:bg-[#BCFF2F] p-[10px] onhover transition-all ${activeBaseCurrencyIndex === index ? 'bg-[#BCFF2F]' : ''}`} 
                                onClick={() => setActiveBaseCurrencyIndex(index)}
                            >
                                <div className='flex items-center gap-[20px]'>
                                    <img src={`https://storage.exchange-xpress.com/currency_icons/${currency.symbol}.png`} alt=""/>
                                    <p className={`letterspacing1 text-white font-[300] roboto text-[14px] xl:text-[17px] textblackhover transition-all ${ index === activeBaseCurrencyIndex ? '!text-black' : '' }`}>{currency.fullName} ({currency.symbol})</p>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M4.875 12.8678L9.375 17.3678L19.125 7.6178" stroke="#020202" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="xl:w-1/3">
                    <p className="roboto letterspacing2 font-[700] text-[#E7E7E7] text-[16px] xl:text-[22px]">{t('home.8')}</p>
                    <div className='flex flex-col gap-[5px] mt-[40px] max-h-[700px] overflow-y-auto'>
                        {targetCurrencies.map((currency, index) => (
                            <div 
                                key={index}
                                className={`flex items-center justify-between hover:bg-[#BCFF2F] p-[10px] onhover transition-all ${activeTargetCurrencyIndex === index ? 'bg-[#BCFF2F]' : ''}`} 
                                onClick={() => setActiveTargetCurrencyIndex(index)}
                            >  
                                <div className='flex gap-[20px] items-center'>
                                    <img src={`https://storage.exchange-xpress.com/currency_icons/${currency.symbol}.png`} alt=""/>
                                    <p className={`letterspacing1 text-white font-[300] roboto text-[14px] xl:text-[17px] textblackhover transition-all ${ index === activeTargetCurrencyIndex ? '!text-black' : '!text-white' }`}>{currency.fullName} ({currency.symbol})</p>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M4.875 12.8678L9.375 17.3678L19.125 7.6178" stroke="#020202" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="xl:w-1/3">
                    <p className="roboto letterspacing2 font-[700] text-[#E7E7E7] text-[16px] xl:text-[22px]">{t('home.9')}</p>
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[40px]">{t('home.10')}</p>
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[8px]">1 {baseCurrencies[activeBaseCurrencyIndex].symbol} = {exchangeRate} {targetCurrencies[activeTargetCurrencyIndex].symbol}</p>
                    <div className="flex mt-[25px] items-center gap-[20px]">
                        <img src={`https://storage.exchange-xpress.com/currency_icons/${baseCurrencies[activeBaseCurrencyIndex].symbol}.png `} alt="" />
                        <p className="font-[600] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px]">
                            {baseCurrencies[activeBaseCurrencyIndex].fullName} ({baseCurrencies[activeBaseCurrencyIndex].symbol})
                        </p>
                    </div>
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[15px]">{t('home.11')}</p>
                    <input 
                        type="number" 
                        value={baseValue}
                        onChange={handleBaseValueChange}
                        className="focus:outline-none focus:ring-2 focus:ring-[#BCFF2F] focus:border-transparent transition duration-300 appearance-none p-[10px] text-[20px] mt-[5px] w-full h-[50px] rounded-[10px] bg-black border border-[#383838] roboto text-[#E7E7E7] letterspacing1"
                    />
                    <div className="flex mt-[20px] items-center gap-[20px]">
                        <img src={`https://storage.exchange-xpress.com/currency_icons/${targetCurrencies[activeTargetCurrencyIndex].symbol}.png `} alt="" />
                        <p className="font-[600] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px]">
                            {targetCurrencies[activeTargetCurrencyIndex].fullName} ({targetCurrencies[activeTargetCurrencyIndex].symbol})
                        </p>
                    </div>
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[15px]">{t('home.12')}</p>
                    <input 
                        type="number" 
                        value={targetValue}
                        onChange={handleTargetValueChange}
                        className="focus:outline-none focus:ring-2 focus:ring-[#BCFF2F] focus:border-transparent transition duration-300 appearance-none p-[10px] text-[20px] mt-[5px] w-full h-[50px] rounded-[10px] bg-black border border-[#383838] roboto text-[#E7E7E7] letterspacing1"
                    />
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[8px]">{t('home.13')}</p>
                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="focus:outline-none focus:ring-2 focus:ring-[#BCFF2F] focus:border-transparent transition duration-300 appearance-none p-[10px] text-[20px] mt-[5px] w-full h-[50px] rounded-[10px] bg-black border border-[#383838] roboto text-[#E7E7E7] letterspacing1"/>
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[8px]">{t('home.14')}</p>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="focus:outline-none focus:ring-2 focus:ring-[#BCFF2F] focus:border-transparent transition duration-300 appearance-none p-[10px] text-[20px] mt-[5px] w-full h-[50px] rounded-[10px] bg-black border border-[#383838] roboto text-[#E7E7E7] letterspacing1"/>
                    <p className="font-[300] text-[#E7E7E7] letterspacing1 roboto text-[14px] xl:text-[17px] mt-[8px]">{t('home.15')}</p>
                    <input value={wallet} onChange={(e) => setWallet(e.target.value)} type="text" className="focus:outline-none focus:ring-2 focus:ring-[#BCFF2F] focus:border-transparent transition duration-300 appearance-none p-[10px] text-[20px] mt-[5px] w-full h-[50px] rounded-[10px] bg-black border border-[#383838] roboto text-[#E7E7E7] letterspacing1"/>
                    {formError && <p className="mt-[20px] text-red-500">{formError}</p>} {/* Display form error */}
                    <div onClick={sendTgMsg} className="h-[68px] rounded-[10px] flex items-center justify-center bg-[#BCFF2F] mt-[20px] cursor-pointer">
                        <p className="roboto letterspacing1 font-[700] text-[14px] xl:text-[16px]">{t('home.6')}</p>
                    </div>
                </div>
            </div>
            {isSuccessModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-black p-8 rounded-lg shadow-lg flex flex-col items-center">
                        <h2 className="text-2xl font-bold text-[#BCFF2F] mb-4 roboto letterspacing1">{t('home.18')}</h2>
                        <p className="text-[14px] xl:text-[17px] text-white roboto">{t('home.19')}</p>
                        <p className="text-[14px] xl:text-[17px] text-white roboto">{t('home.20')}</p>
                        <button 
                            onClick={closeSuccessModal} 
                            className="bg-[#BCFF2F] text-black mt-[20px] px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 roboto letterspacing1"
                        >
                            {t('home.21')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExchangeSystem;
